import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Blob } from 'activestorage';
import { ServiceDecorator } from './decorators';
import { BaseApiService, JsonApiQueryData } from './base';
import { ListFilter, Tenant, UrlVars } from '@parashift/shared/models';
import { TenantType } from '@parashift/shared/types';
import { QueryParamsService } from '../query-params.service';
import { Endpoint } from '../env.service';

export interface Subtenant {
  id: number;
  disabled: boolean;
  name: string;
  role: string;
  permissions: string[];
  annotation_tenant: null | 'private' | 'public';
  annotation_tenant_id: number;
}

export interface AllowedSubtenants {
  allowed_subtenants: Subtenant[];
}

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => Tenant,
  endpointUrl: TenantType
})
export class TenantService extends BaseApiService<Tenant> {
  className = TenantService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService
  ) {
    super(http, queryParamsService);
    this.baseUrl = Endpoint.id;
    this.apiVersion = this.environment.endpoints.version
  }

  lookup(id: string, listFilter?: ListFilter) {
    const urlVars: UrlVars = { after_endpoint_url: 'lookup' };
    return this.findRecord(id, listFilter, undefined, urlVars);
  }

  lookupAll(listFilter: ListFilter) {
    const urlVars: UrlVars = { postfix: 'lookup' };
    return this.findAll(listFilter, undefined, urlVars);
  }

  getCurrentSubTenants(tenant_id: string): Observable<JsonApiQueryData<Tenant>> {
    const urlVars = {
      postfix: tenant_id + '/subtenants'
    };
    return this.findAll({}, undefined, urlVars);
  }

  getAllowedSubTenants(tenant_id: string): Observable<AllowedSubtenants> {
    const url = this.buildUrl();
    return this.http.get(url + '/' + tenant_id + '/allowed_subtenants') as Observable<AllowedSubtenants>;
  }

  addSubTenant(tenant_id: string, sub_tenant_id: number) {
    const url = this.buildUrl();
    return this.http.patch(url + '/' + tenant_id + '/add_sub_tenant' + '/' + sub_tenant_id, {});
  }

  removeSubTenant(tenant_id: string, sub_tenant_id: number) {
    const url = this.buildUrl();
    return this.http.patch(url + '/' + tenant_id + '/remove_sub_tenant' + '/' + sub_tenant_id, {});
  }

  addLogoFromDirectUpload(fileHandler: Blob, tenant_id: number) {
    const url = this.buildUrl();
    return this.http.post(url + '/' + tenant_id + '/upload_logo', {
      blob_signed_id: fileHandler.signed_id
    });
  }

  enableUrlIntegration(tenant_id: number) {
    const url = this.buildUrl();
    return this.http.patch(url + '/' + tenant_id + '/enable_embedded_login', {});
  }

  disableUrlIntegration(tenant_id: number) {
    const url = this.buildUrl();
    return this.http.patch(url + '/' + tenant_id + '/disable_embedded_login', {});
  }
}
